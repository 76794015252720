class InstaFeed extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.currentImageIndex = 0;
    this.imageInterval = null;
  }

  connectedCallback() {
    this.fetchImages().then((igimages) => {
      this.render(igimages);
    });
  }
  async fetchImages() {
    return [
      {
        src: require("../../assets/images/instagram/castellana8ig_1.png"),
        alt: "",
        title: "",
        link: "https://www.instagram.com/castellana8/",
      },
      {
        src: require("../../assets/images/instagram/castellana8ig_2.png"),
        alt: "",
        title: "",
        link: "https://www.instagram.com/castellana8/",
      },
      {
        src: require("../../assets/images/instagram/castellana8ig_3.png"),
        alt: "",
        title: "",
        link: "https://www.instagram.com/castellana8/",
      },
      {
        src: require("../../assets/images/instagram/castellana8ig_4.png"),
        alt: "",
        title: "",
        link: "https://www.instagram.com/castellana8/",
      },
    ];
  }

  render(igimages) {
    const styles = `
    <style>
    .instaFeed {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      overflow: hidden;
      margin: 0 1rem;
  }
  
  .instaFeed a {
      min-width: 15rem;
      min-height: 15rem;
      margin: 0 2.5rem;
      overflow: hidden;
    
  }
  
  .instaFeed a img {
      width: 15rem;
      height: 15rem;
      object-fit: cover;
      transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .instaFeed a img:hover {
    
      transform: scale(1.3);
      z-index: 1;
  }
  
  @media (max-width: 900px) {
      .instaFeed {
          width: 20rem;
      }
      .instaFeed div {
          width: 100%;
      }
  }
  
  @media (max-width: 1200px) and (min-width: 901px) {
      .instaFeed {
          width: 40rem;
      }
      .instaFeed div {
          width: 50%;
      }
  }
  
  @media (max-width: 1500px) and (min-width: 1201px) {
      .instaFeed {
          width: 60rem;
      }
      .instaFeed div {
          width: 33.3%;
      }
  }
  
  @media (min-width: 1501px) {
      .instaFeed {
          width: 80rem;
      }
      .instaFeed div {
          width: 25%;
      }
  }
  
</style>

      `;

    const imagesHtml = igimages
      .map(
        (igimg) =>
          `<div><a href="${igimg.link}" target="_blank"><img src="${igimg.src}" alt="Instagram Image"></a></div>`
      )
      .join("");

    this.shadowRoot.innerHTML = `${styles}<div class="instaFeed">${imagesHtml}</div>`;
  }
}

customElements.define("insta-feed", InstaFeed);
