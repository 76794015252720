export function initializeTouchEvents(element, onSwipeLeft, onSwipeRight) {
  let xDown = null;
  let yDown = null;

  element.addEventListener("touchstart", handleTouchStart, false);
  element.addEventListener("touchmove", handleTouchMove, false);

  function handleTouchStart(evt) {
    const firstTouch = evt.touches[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) return;

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;
    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    // Prevent vertical scroll
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      evt.preventDefault();
      if (xDiff > 0) {
        onSwipeLeft();
      } else {
        onSwipeRight();
      }
    }

    xDown = null;
    yDown = null;
  }
}
