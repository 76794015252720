import "core-js/features/array";
import { __ } from "./i18n";
import { initializeTouchEvents } from "./touchEvents";

let current_page = 0;
const max_page = 22;

const config = [
  {
    title: "",
    pages: [0],
  },
  {
    title: "Snacks",
    pages: [1, 2],
  },
  {
    title: "Cocktails",
    pages: [3, 4, 5, 6],
  },
  {
    title: "Champagnes",
    pages: [7, 8, 9],
  },
  {
    title: "Tequilas",
    pages: [10, 11],
  },
  {
    title: "Bottles",
    pages: [12, 13, 14, 15, 16],
  },
  {
    title: "Wines",
    pages: [17, 18, 19, 20, 21, 22],
  },
];

document.addEventListener("DOMContentLoaded", () => {
  let allSection = document.getElementById("food-and-drink");
  let gotomenu = document.getElementById("menu-section-title");
  let foodMenu = document.getElementById("food-menu");
  let cocktailsMenu = document.getElementById("cocktails-menu");
  let champagnesMenu = document.getElementById("champagnes-menu");
  let tequilasMenu = document.getElementById("tequilas-menu");
  let bottlesMenu = document.getElementById("bottles-menu");
  let winesMenu = document.getElementById("wines-menu");

  gotomenu.addEventListener("click", () => {
    goPage(0);
  });

  foodMenu.addEventListener("click", () => {
    toggleMenuPage(config[1]);
  });

  cocktailsMenu.addEventListener("click", () => {
    toggleMenuPage(config[2]);
  });

  champagnesMenu.addEventListener("click", () => {
    toggleMenuPage(config[3]);
  });

  tequilasMenu.addEventListener("click", () => {
    toggleMenuPage(config[4]);
  });

  bottlesMenu.addEventListener("click", () => {
    toggleMenuPage(config[5]);
  });

  winesMenu.addEventListener("click", () => {
    toggleMenuPage(config[6]);
  });
  initializeTouchEvents(allSection, goNextPage, goPreviousPage);
  // si sobre allSection hay un evento de tocar y arrastrar a la izquierda va a la siguiente página
  // si sobre allSectionhay un evento de tocar y arrastrar a la derecha va a la página anterior

  goPage(current_page);
});

function toggleMenuPage(itemCollection) {
  goPage(
    Array.isArray(itemCollection.pages)
      ? itemCollection.pages[0]
      : itemCollection.pages
  );
}

function goNextPage() {
  if (current_page === max_page) return;
  goPage(current_page + 1);
}

function goPreviousPage() {
  if (current_page === 0) return;
  else goPage(current_page - 1);
}

function goPage(pagenum) {
  current_page = pagenum;
  const menuContainers = document.querySelectorAll(
    "#food-and-drink .menu__container"
  );
  for (let container of menuContainers) {
    if (!container.classList.contains("hidden")) {
      container.classList.add("hidden");
    }
  }
  const specificMenuPage = document.getElementById(`menu-page-${current_page}`);
  if (specificMenuPage) {
    specificMenuPage.classList.remove("hidden");
  }
  updateMenuHeader();
  updateMenuFooter();
}

function updateMenuHeader() {
  const { previous, current, next } = getConfigByPage(current_page);
  if (current === null) return;
  const menuHeader = document.querySelector("#food-and-drink .menu__header");
  const headerSpan = document.querySelector("#menu-section-title");
  const headerGoNext = document.querySelector(
    "#food-and-drink .menu__header .next"
  );
  const headerGoPrev = document.querySelector(
    "#food-and-drink .menu__header .prev"
  );
  const title = document.getElementById("menu-section-title");

  if (current_page === 0) {
    menuHeader.classList.add("hidden");
  } else {
    if (menuHeader.classList.contains("hidden")) {
      menuHeader.classList.remove("hidden");
    }
    headerSpan.innerHTML = __(current.title);
  }

  headerGoNext.onclick =
    next !== null && next !== undefined
      ? () => goPage(Array.isArray(next.pages) ? next.pages[0] : next.pages)
      : null;
  headerGoPrev.onclick =
    previous !== null
      ? () =>
          goPage(
            Array.isArray(previous.pages) ? previous.pages[0] : previous.pages
          )
      : null;
}

function updateMenuFooter() {
  const footer = document.querySelector("#food-and-drink .menu__footer");
  const { current } = getConfigByPage(current_page);
  const footerSpan = document.querySelector(
    "#food-and-drink .menu__footer span"
  );
  const footerGoNext = document.querySelector(
    "#food-and-drink .menu__footer .next"
  );
  const footerGoPrev = document.querySelector(
    "#food-and-drink .menu__footer .prev"
  );

  if (current_page === 0) {
    footer.classList.add("hidden");
  } else {
    footer.classList.remove("hidden");
    footerSpan.innerHTML =
      (Array.isArray(current.pages)
        ? current.pages.indexOf(current_page) + 1
        : 1) +
      "/" +
      (Array.isArray(current.pages) ? current.pages.length : 1);

    footerGoPrev.onclick = goPreviousPage;

    if (current_page === max_page) {
      footerGoNext.onclick = null;
    } else {
      footerGoNext.onclick = goNextPage;
    }
  }
}

function getConfigByPage(page) {
  let configWantedIndex = -1;
  let previousConfig = null;
  let currentConfig = null;
  let nextConfig = null;

  for (let i = 0; i < config.length; i++) {
    const pages = config[i].pages;
    if (pages.indexOf(page) === -1) continue;
    else {
      configWantedIndex = i;
    }
  }
  if (configWantedIndex !== -1) {
    currentConfig = config[configWantedIndex];
    if (configWantedIndex > 0) {
      previousConfig = config[configWantedIndex - 1];
    }
    if (configWantedIndex < config.length) {
      nextConfig = config[configWantedIndex + 1];
    }
  } else {
    return { previous: null, current: null, next: null };
  }
  return { previous: previousConfig, current: currentConfig, next: nextConfig };
}
