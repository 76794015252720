const goLeft = `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 18L9 12L15 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;
const goRight = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 6L15 12L9 18" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`;
class DateTimePickerElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot.innerHTML = `
    <style>
    :host {
      display: block;      
      margin: 0 auto;
    }
    
    .calendar-nav, .hour-nav {
      text-transform: capitalize; 
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      padding: 0.625rem;
    }
    .calendar-nav {
      font-size: 1.2rem;
      font-weight: 600;
    }
    .hour-nav {
      font-size: 1rem;
    }
    button{
      background-color: transparent;
      border: none;
      text-size: 2 rem;
      font-weight: bolder;
      color: #FFF;
      cursor: pointer;
      display: flex;
      align-items: center;  
    }
    button:hover {
      background-color: #0000002b;
      border-radius: 0.3125rem;
    }
    
    #daysGrid {
      width: 100%;
      height: fit-content;
      border-collapse: collapse;
    }
    #daysGrid tr{
      height: 2rem;
    }
    #daysGrid th, #daysGrid td {
      width: calc(100% / 7);
      max-height: 2rem;
      text-align: center;
      box-sizing: border-box; 
    }
    
    #daysGrid td:not(:empty) {
      cursor: pointer;
      border-radius: 0.3125rem;
    }
    
    #daysGrid td:not(:empty):not(.selected):hover {
      background-color: #00000082;
      color: #FFF;
    }
    
    .disabled {
      color: #ccc;
      pointer-events: none;
    }
    
    .selected {
      background-color: #fff;
      color: #000;
      font-weight: bold;      
    }

    .calendar-nav__tableCont {
      height: 15rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    
  </style>
      <div class="calendar-nav">
        <button id="prevMonth">${goLeft}</button>
        <span id="monthYear"></span>
        <button id="nextMonth">${goRight}</button>
      </div>
      <div class="calendar-nav__tableCont">
        <table id="daysGrid">
          <thead>
            <tr id="daysOfWeek"></tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>      
      <div class="hour-nav">
        <button id="decreaseHour">${goLeft}</button>
        <span id="selectedHour">00:00</span>
        <button id="increaseHour">${goRight}</button>
      </div>
    `;
    this.currentDate = new Date();
    this.selectedDate = new Date();
    this.selectedHour = 0;
    this.startHour = 0;
    this.endHour = 23;
    this.renderCalendar = this.renderCalendar.bind(this);
    this.updateSelectedHourDisplay = this.updateSelectedHourDisplay.bind(this);
    this.adjustHour = this.adjustHour.bind(this);
  }

  connectedCallback() {
    this.shadowRoot
      .getElementById("prevMonth")
      .addEventListener("click", () => this.navigateMonth(-1));
    this.shadowRoot
      .getElementById("nextMonth")
      .addEventListener("click", () => this.navigateMonth(1));
    this.shadowRoot
      .getElementById("decreaseHour")
      .addEventListener("click", () => this.adjustHour(-1));
    this.shadowRoot
      .getElementById("increaseHour")
      .addEventListener("click", () => this.adjustHour(1));
    this.renderCalendar();
    this.updateSelectedHourDisplay();
  }

  adjustHour(increment) {
    this.selectedHour += increment;
    if (this.selectedHour < this.startHour) {
      this.selectedHour = this.endHour;
    } else if (this.selectedHour > this.endHour) {
      this.selectedHour = this.startHour;
    }
    this.updateSelectedHourDisplay();
  }

  updateSelectedHourDisplay() {
    const hourDisplay = this.shadowRoot.getElementById("selectedHour");
    hourDisplay.textContent = `${this.selectedHour
      .toString()
      .padStart(2, "0")}:00 h`;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "start-hour") {
      this.startHour = Math.max(0, Math.min(parseInt(newValue), 23));
    } else if (name === "end-hour") {
      this.endHour = Math.max(this.startHour, Math.min(parseInt(newValue), 23));
    }
    this.updateSelectedHourDisplay();
  }

  static get observedAttributes() {
    return ["start-hour", "end-hour"];
  }

  renderCalendar() {
    const monthYearDisplay = this.shadowRoot.getElementById("monthYear");
    const daysOfWeekRow = this.shadowRoot.getElementById("daysOfWeek");
    const daysGrid = this.shadowRoot
      .getElementById("daysGrid")
      .querySelector("tbody");

    const year = this.currentDate.getFullYear();
    const month = this.currentDate.getMonth();

    const monthStr = new Date(year, month).toLocaleDateString(this.language, {
      month: "long",
    });
    const yearTwoDigits = new Date(year, month).toLocaleDateString(
      this.language,
      {
        year: "2-digit",
      }
    );

    monthYearDisplay.textContent = `${monthStr} ${yearTwoDigits}`;

    const daysOfWeek =
      this.language === "en"
        ? ["S", "M", "T", "W", "T", "F", "S"]
        : ["L", "M", "X", "J", "V", "S", "D"];
    daysOfWeekRow.innerHTML = daysOfWeek
      .map((day) => `<th>${day}</th>`)
      .join("");

    daysGrid.innerHTML = "";
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const numberOfDaysInMonth = new Date(year, month + 1, 0).getDate();

    let adjustment =
      this.language === "en"
        ? firstDayOfMonth
        : firstDayOfMonth === 0
        ? 6
        : firstDayOfMonth - 1;
    let dayCell = 1 - adjustment;

    for (let row = 0; row < 6; row++) {
      const weekRow = document.createElement("tr");
      for (let col = 0; col < 7; col++) {
        const dayElement = document.createElement("td");
        if (dayCell > 0 && dayCell <= numberOfDaysInMonth) {
          const currentDay = new Date(year, month, dayCell);
          dayElement.textContent = dayCell;

          if (
            (this.minDate && currentDay < this.minDate) ||
            (this.maxDate && currentDay > this.maxDate)
          ) {
            dayElement.classList.add("disabled");
          } else {
            dayElement.addEventListener("click", () => {
              this.selectedDate = currentDay;
              this.renderCalendar(); // Re-render the calendar to reflect the new selection
            });

            // Highlight the selected day
            if (
              this.selectedDate.getDate() === currentDay.getDate() &&
              this.selectedDate.getMonth() === currentDay.getMonth() &&
              this.selectedDate.getFullYear() === currentDay.getFullYear()
            ) {
              dayElement.classList.add("selected");
            }
          }
        }
        weekRow.appendChild(dayElement);
        dayCell++;
      }
      daysGrid.appendChild(weekRow);
      if (dayCell > numberOfDaysInMonth) break;
    }
  }

  updateSelectedDayStyle() {
    const days = this.shadowRoot.querySelectorAll(".day");
    days.forEach((day) => {
      day.classList.remove("selected");
    });
    // Find and style the selected day if it's in the current month view
    Array.from(days)
      .find((day) => day.textContent == this.selectedDate.getDate())
      ?.classList.add("selected");
  }

  navigateMonth(direction) {
    this.currentDate.setMonth(this.currentDate.getMonth() + direction);
    this.renderCalendar();
  }

  updateNavigationButtons() {
    const prevMonthButton = this.shadowRoot.getElementById("prevMonth");
    const nextMonthButton = this.shadowRoot.getElementById("nextMonth");

    const prevMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() - 1,
      1
    );
    const nextMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() + 1,
      1
    );

    prevMonthButton.disabled = this.minDate && prevMonth < this.minDate;
    nextMonthButton.disabled = this.maxDate && nextMonth > this.maxDate;
  }
  getSelectedDateTime() {
    const selectedDate = this.selectedDate;
    selectedDate.setHours(this.selectedHour, 0, 0, 0);
    return selectedDate;
  }
  getSelectedDateTimeAsString() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return this.getSelectedDateTime().toLocaleString(undefined, options);
  }
}

window.customElements.define("date-time-picker", DateTimePickerElement);
