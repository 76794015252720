import "./styles/main.scss";
import "./scripts/webcomponents/dateTimePicker.js";
import "./scripts/webcomponents/instaFeed.js";
import "./scripts/i18n.js";
//import "./scripts/form.js";
import "./scripts/scroll.js";
// import "./scripts/cocktails.js";
import "./scripts/roomCarousel.js";
import "./scripts/foodAndDrink.js";
import dossierPdf from "./assets/dossier_castellana8.pdf";
// import { iframeResizer } from "iframe-resizer";

// const navLanguage = getNavLanguage();

document.addEventListener("DOMContentLoaded", () => {
  // document
  //   .getElementById("covermanagerCastellana8")
  //   .addEventListener("onload", () => {
  //     iframeResizer({ log: false });
  //   });

  document.getElementById("download-dossier").addEventListener("click", () => {
    const link = document.createElement("a");
    link.href = dossierPdf;
    link.download = "dossier_castellana8.pdf";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });

  var cookiePopup = document.getElementById("cookie-banner");
  var acceptCookiesBtn = document.getElementById("accept-cookies-btn");

  var cookiesAccepted = localStorage.getItem("cookiesAccepted");
  if (!cookiesAccepted) {
    cookiePopup.style.display = "block";
  } else {
    cookiePopup.style.display = "none";
  }

  acceptCookiesBtn.addEventListener("click", () => {
    cookiePopup.style.display = "none";
    localStorage.setItem("cookiesAccepted", "true");
  });
});
