let langCode = "en";
function detectLanguageAndRedirect() {
  const currentUrl = window.location.href;
  const urlObj = new URL(currentUrl);
  const hostname = urlObj.hostname;
  if (hostname.startsWith("es.")) {
    langCode = "es";
  }
}

window.onload = detectLanguageAndRedirect();

export function __(word) {
  if (diccionario[word] !== undefined) {
    if (diccionario[word][langCode] !== undefined) {
      return diccionario[word][langCode];
    }
  }
  return word;
}

const diccionario = {
  Snacks: { es: "Picoteo" },
  Cocktails: { es: "Cócteles" },
  Bottles: { es: "Botellas" },
  Wines: { es: "Vinos" },
};
