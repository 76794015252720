const menuIcon = document.getElementById("menu-icon");
const navbar = document.getElementById("desktop-menu");
const headerImg = document.getElementById("header-img-castellana8");

document.addEventListener("DOMContentLoaded", () => {
  document.getElementById("menu-icon").addEventListener("click", () => {
    menuIcon.classList.toggle("open");
  });

  window.onscroll = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      headerImg.classList.add("hidden");
      navbar.classList.remove("hidden");
    } else {
      headerImg.classList.remove("hidden");
      navbar.classList.add("hidden");
    }
    if (window.scrollY > window.innerHeight) {
      navbar.classList.add("reduced");
    } else {
      navbar.classList.remove("reduced");
    }
  };
});
