document.addEventListener("DOMContentLoaded", () => {
  document.querySelector(".arrow.right").addEventListener("click", () => {
    rotateClassesRight();
  });
  document.querySelector(".arrow.left").addEventListener("click", () => {
    rotateClassesLeft();
  });
  setInterval(() => {
    rotateClassesRight();
  }, 10000);
});

const images = document.querySelectorAll(".carrusel .image");
const descriptions = document.querySelectorAll(".descriptions .description");
const visibleImg = document.getElementById("visibleRoomImg");

function rotateClassesLeft() {
  const firstClass = images[0].classList[1];
  for (let i = 0; i < images.length - 1; i++) {
    images[i].classList.replace(
      images[i].classList[1],
      images[i + 1].classList[1]
    );
  }
  images[images.length - 1].classList.replace(
    images[images.length - 1].classList[1],
    firstClass
  );
  showDescription();
}

function rotateClassesRight() {
  const lastClass = images[images.length - 1].classList[1];
  for (let i = images.length - 1; i > 0; i--) {
    images[i].classList.replace(
      images[i].classList[1],
      images[i - 1].classList[1]
    );
  }
  images[0].classList.replace(images[0].classList[1], lastClass);
  showDescription();
}

function showDescription() {
  let room = null;
  images.forEach((image) => {
    if (image.classList.contains("image-center")) {
      room = image.getAttribute("room");
      let imgCont = image.firstChild;
      visibleImg.classList.add("fade-out");

      setTimeout(() => {
        visibleImg.classList.add("fade-in");
        visibleImg.classList.remove("fade-out");
        visibleImg.src = imgCont.src;
        visibleImg.alt = imgCont.alt;
        setTimeout(() => visibleImg.classList.remove("fade-in"), 200);
      }, 200);
    }
  });
  if (room !== null) {
    descriptions.forEach((description) => {
      if (description.getAttribute("room") == room) {
        description.classList.add("visible");
      } else {
        description.classList.remove("visible");
      }
    });
  }
}
